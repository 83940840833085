var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"dashboard__patient"}},[_c('h1',[_vm._v(_vm._s(_vm.$t('dashboard.doctor.title', { patientName: _vm.user.username })))]),_c('section',[_c('hover-dashboard',{attrs:{"route-name":"PatientForms","options":{ patientId: _vm.user.id, surveyCat: 'INCLUSION' },"disabled":false},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',[_vm._v("mdi-account-plus-outline")])]},proxy:true},{key:"text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dashboard.doctor.patientStart'))+" ")]},proxy:true}])}),_c('hover-dashboard',{attrs:{"route-name":"GeneralCalendar","options":{ patientId: _vm.user.id },"disabled":!_vm.patientIncluded},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('u-icon',{attrs:{"name":"us-calendar"}})]},proxy:true},{key:"text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dashboard.doctor.calendar'))+" ")]},proxy:true}])}),_c('hover-dashboard',{attrs:{"route-name":"GeneralCalendarPdf","options":{ patientId: _vm.user.id, patientName: _vm.user.username },"disabled":!_vm.patientIncluded || !_vm.user.validCalendar},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',[_vm._v("mdi-file-pdf-outline")])]},proxy:true},{key:"text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dashboard.doctor.calendarPdf'))+" ")]},proxy:true}])}),_c('hover-dashboard',{attrs:{"route-name":"SurveyDayList","options":{ userId: _vm.user.id, surveyCat: 'SURVEY' },"disabled":!_vm.patientIncluded || !_vm.user.validCalendar},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',[_vm._v("mdi-account-question-outline")])]},proxy:true},{key:"text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dashboard.doctor.patientSurveys'))+" ")]},proxy:true}])}),_c('hover-dashboard',{attrs:{"route-name":"SurveyDayList","options":{ userId: _vm.user.id, surveyCat: 'FORM' },"disabled":!_vm.patientIncluded ||
          !_vm.user.validCalendar ||
          (!_vm.isTechnician && !_vm.isDoctor && !_vm.isAdmin)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',[_vm._v("mdi-stethoscope")])]},proxy:true},{key:"text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dashboard.doctor.doctorSurveys'))+" "),_c('br'),_c('span',{staticClass:"highlight"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.doctor.consultationFill'))+" ")])]},proxy:true}])}),_c('hover-dashboard',{attrs:{"route-name":"PatientForms","options":{ patientId: _vm.user.id, surveyCat: 'END' },"disabled":!_vm.patientIncluded ||
          !_vm.user.validCalendar ||
          (!_vm.isTechnician && !_vm.isDoctor && !_vm.isAdmin)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',[_vm._v("mdi-account-remove-outline")])]},proxy:true},{key:"text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dashboard.doctor.patientStop'))+" ")]},proxy:true}])}),_c('hover-dashboard',{attrs:{"route-name":"SummaryPatient","options":{ patientId: _vm.user.id, patientId: _vm.user.id },"disabled":!_vm.patientIncluded || !_vm.user.validCalendar},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',[_vm._v("mdi-clipboard-list-outline")])]},proxy:true},{key:"text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dashboard.doctor.patientSummary'))+" ")]},proxy:true}])}),_c('hover-dashboard',{attrs:{"route-name":"PatientCsvResults","options":{ patientId: _vm.user.id, patientName: _vm.user.username, full: 1 },"disabled":!_vm.patientIncluded ||
          !_vm.user.validCalendar ||
          (!_vm.isTechnician && !_vm.isResearcher && !_vm.isAdmin)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',[_vm._v("mdi-file-delimited-outline")])]},proxy:true},{key:"text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dashboard.doctor.patientCsvResultsFull'))+" ")]},proxy:true}])}),_c('hover-dashboard',{attrs:{"route-name":"PatientCsvResults","options":{ patientId: _vm.user.id, patientName: _vm.user.username, full: 0 },"disabled":!_vm.patientIncluded ||
          !_vm.user.validCalendar ||
          (!_vm.isTechnician && !_vm.isResearcher && !_vm.isAdmin)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',[_vm._v("mdi-file-delimited-outline")])]},proxy:true},{key:"text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dashboard.doctor.patientCsvResults'))+" ")]},proxy:true}])}),_c('v-hover',{staticClass:"mx-auto",attrs:{"id":"progression"}},[_c('v-card',[_c('Progress',{attrs:{"strokeColor":"#8888ff","transitionDuration":1000,"radius":50,"strokeWidth":10,"value":_vm.progression}}),_c('v-card-text',{staticClass:"my-9 text-left"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.titles.progression'))+" ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
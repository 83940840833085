<template>
  <v-hover v-slot:default="{ hover }"
           :disabled="disabled"
           :class="{ disabled }"
           @click="$emit('click', $event)"
  >
    <v-card :elevation="hover ? 10 : 2"
            :to="cardLink"
            class="mx-auto"
            @click="$emit('click', $event)"
    >
      <v-card-text>
        <p>
          <slot name="icon"/>
        </p>
        <p>
          <slot name="text"/>
        </p>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'HoverDashboard',

  props: {
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    isLink: {
      required: false,
      type: Boolean,
      default: true,
    },
    options: {
      required: false,
      type: Object,
      default: () => {
        return {}
      },
    },
    routeName: {
      required: false,
      type: String,
      default: '',
    },
  },

  computed: {
    cardLink () {
      return Object.keys(this.options).length
             ? !this.disabled && this.isLink ? { name: this.routeName, params: this.options } : null
             : !this.disabled && this.isLink ? { name: this.routeName } : null
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/style";

.v-card {
  width: 100%;
  height: 130px;
  background-color: $whiteTransparent;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;

    .v-card__text::v-deep {
      i {
        font-size: 40px;
        color: $disabledColorText;
      }

      svg {
        stroke: $disabledColorText;
      }

      p {
        color: $disabledColorText;
      }
    }
  }

  .v-card__text::v-deep {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 25px 5px;

    i {
      font-size: 40px;
      color: $primaryColor;
    }

    svg {
      stroke: $primaryColor;
      width: 40px;
      height: 40px;
    }

    p {
      margin: 0;
      font-size: 25px;
      text-align: center;
    }
  }
}
</style>

<template>
  <section id="dashboard__patient">
    <h1>{{ $t('dashboard.doctor.title', { patientName: user.username }) }}</h1>

    <section>
      <hover-dashboard
        route-name="PatientForms"
        :options="{ patientId: user.id, surveyCat: 'INCLUSION' }"
        :disabled="false"
      >
        <template v-slot:icon>
          <v-icon>mdi-account-plus-outline</v-icon>
        </template>

        <template v-slot:text>
          {{ $t('dashboard.doctor.patientStart') }}
        </template>
      </hover-dashboard>

      <hover-dashboard
        route-name="GeneralCalendar"
        :options="{ patientId: user.id }"
        :disabled="!patientIncluded"
      >
        <template v-slot:icon>
          <u-icon name="us-calendar" />
        </template>

        <template v-slot:text>
          {{ $t('dashboard.doctor.calendar') }}
        </template>
      </hover-dashboard>

      <hover-dashboard
        route-name="GeneralCalendarPdf"
        :options="{ patientId: user.id, patientName: user.username }"
        :disabled="!patientIncluded || !user.validCalendar"
      >
        <template v-slot:icon>
          <v-icon>mdi-file-pdf-outline</v-icon>
        </template>

        <template v-slot:text>
          {{ $t('dashboard.doctor.calendarPdf') }}
        </template>
      </hover-dashboard>

      <hover-dashboard
        route-name="SurveyDayList"
        :options="{ userId: user.id, surveyCat: 'SURVEY' }"
        :disabled="!patientIncluded || !user.validCalendar"
      >
        <template v-slot:icon>
          <v-icon>mdi-account-question-outline</v-icon>
        </template>

        <template v-slot:text>
          {{ $t('dashboard.doctor.patientSurveys') }}
        </template>
      </hover-dashboard>

      <hover-dashboard
        route-name="SurveyDayList"
        :options="{ userId: user.id, surveyCat: 'FORM' }"
        :disabled="
          !patientIncluded ||
            !user.validCalendar ||
            (!isTechnician && !isDoctor && !isAdmin)
        "
      >
        <template v-slot:icon>
          <v-icon>mdi-stethoscope</v-icon>
        </template>

        <template v-slot:text>
          {{ $t('dashboard.doctor.doctorSurveys') }}
          <br />
          <span class="highlight">
            {{ $t('dashboard.doctor.consultationFill') }}
          </span>
        </template>
      </hover-dashboard>

      <hover-dashboard
        route-name="PatientForms"
        :options="{ patientId: user.id, surveyCat: 'END' }"
        :disabled="
          !patientIncluded ||
            !user.validCalendar ||
            (!isTechnician && !isDoctor && !isAdmin)
        "
      >
        <template v-slot:icon>
          <v-icon>mdi-account-remove-outline</v-icon>
        </template>

        <template v-slot:text>
          {{ $t('dashboard.doctor.patientStop') }}
        </template>
      </hover-dashboard>

      <hover-dashboard
        route-name="SummaryPatient"
        :options="{ patientId: user.id, patientId: user.id }"
        :disabled="!patientIncluded || !user.validCalendar"
      >
        <template v-slot:icon>
          <v-icon>mdi-clipboard-list-outline</v-icon>
        </template>

        <template v-slot:text>
          {{ $t('dashboard.doctor.patientSummary') }}
        </template>
      </hover-dashboard>

      <hover-dashboard
        route-name="PatientCsvResults"
        :options="{ patientId: user.id, patientName: user.username, full: 1 }"
        :disabled="
          !patientIncluded ||
            !user.validCalendar ||
            (!isTechnician && !isResearcher && !isAdmin)
        "
      >
        <template v-slot:icon>
          <v-icon>mdi-file-delimited-outline</v-icon>
        </template>

        <template v-slot:text>
          {{ $t('dashboard.doctor.patientCsvResultsFull') }}
        </template>
      </hover-dashboard>

      <hover-dashboard
        route-name="PatientCsvResults"
        :options="{ patientId: user.id, patientName: user.username, full: 0 }"
        :disabled="
          !patientIncluded ||
            !user.validCalendar ||
            (!isTechnician && !isResearcher && !isAdmin)
        "
      >
        <template v-slot:icon>
          <v-icon>mdi-file-delimited-outline</v-icon>
        </template>

        <template v-slot:text>
          {{ $t('dashboard.doctor.patientCsvResults') }}
        </template>
      </hover-dashboard>

      <v-hover class="mx-auto" id="progression">
        <v-card>
          <Progress
            strokeColor="#8888ff"
            :transitionDuration="1000"
            :radius="50"
            :strokeWidth="10"
            :value="progression"
          >
          </Progress>
          <v-card-text class="my-9 text-left">
            {{ $t('dashboard.titles.progression') }}
          </v-card-text>
        </v-card>
      </v-hover>
    </section>
  </section>
</template>

<script>
import HoverDashboard from '@/components/Dashboard/HoverDashboard'
import { mapActions, mapGetters } from 'vuex'
import Progress from 'easy-circular-progress'

export default {
  name: 'DashboardOfAPatient',

  components: {
    Progress,
    HoverDashboard: HoverDashboard,
  },

  props: {
    patientId: {
      required: true,
    },
  },

  data: () => ({
    progression: 0
  }),

  computed: {
    ...mapGetters(['user', 'userConnected']),
    patientIncluded() {
      return this.user.included
    },
    isTechnician() {
      return this.userConnected.groups.find(group => group.name === 'TEC')
    },
    isResearcher() {
      return this.userConnected.groups.find(group => group.name === 'CHR')
    },
    isDoctor() {
      return this.userConnected.groups.find(group => group.name === 'MED')
    },
    isAdmin() {
      return this.userConnected.groups.find(group => group.name === 'ADM')
    },
  },

  created() {
    this.loadUser({ id: this.patientId }).then(() => {
      this.progression = this.getProgression();
    })
  },

  methods: {
    ...mapActions(['loadUser']),

    getProgression() {
      if (this.user.milestonesData == null) return 0
      let keys = Object.keys(this.user.milestonesData).map(v => parseInt(v))
      if (keys.length === 0) return 0

      let lastMilestone = Math.max(...keys)
      let lastDay = this.user.milestonesData[lastMilestone]['day']['value']
      if (lastDay === undefined) return 0

      let progression_value = parseFloat(
        100 * (this.user.currentStudyDay / lastDay)
      ).toFixed(2)

      return progression_value > 100 ? 100 : progression_value
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/style/style';

#dashboard__patient {
  > section {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;

    @media all and (min-width: $s) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media all and (min-width: $m) {
      grid-template-columns: repeat(3, 1fr);
    }

    .highlight {
      color: rgb(200, 0, 0);
    }
  }
}

#progression {
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 130px;

  .vue-circular-progress {
    padding-left: 20px;
    padding-top: 5px;
    padding-right: 0;
  }

  div {
    font-family: 'Unistra A', sans-serif;
    font-weight: normal;
    font-size: 25px;
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>
